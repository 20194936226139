/* You can add global styles to this file, and also import other style files */
h1, h2 {
  margin:0px;
  padding: 0px;
}

#title {
  margin: 15px;
  text-align: center;
  font-size: 80px;
}